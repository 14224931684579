'use client';

import { useEffect, useState } from 'react';

import { RBWidget } from '@ui-kit/main-frontend-ui-kit/dist/src/components/Bookies/RBWidget';
import { useLocale } from 'next-intl';
import LazyHydrate from 'react-lazy-hydration';

import {
	BETTING_SPORT_URLS,
	bookieDefRatingUrls,
	casinoDefRatingUrls,
	getRbWidgetData,
	IRbWidgetLinks,
} from '@utils/bookie';
import { TRIBUNA_COM_URL } from '@utils/constants';
import { getCoutryCode } from '@utils/helpers';
import { RbWidgetData } from '@utils/types';

interface IRBWidgetHOC {
	className?: string;
	rbWidgetLinks: IRbWidgetLinks;
	showBookie?: boolean;
	showCasino?: boolean;
}

const RBWidgetHOC = ({
	rbWidgetLinks,
	className = '',
	showBookie = true,
	showCasino = false,
}: IRBWidgetHOC) => {
	const locale = useLocale();
	const [data, setData] = useState<RbWidgetData>();

	const getRbInfo = async () => {
		const countryCode = await getCoutryCode();
		const rbWidgetData = await getRbWidgetData({
			countryCode,
			locale,
			showBookie,
			showCasino,
		});
		setData(rbWidgetData);
	};

	useEffect(() => {
		getRbInfo();
	}, []);

	return (
		<>
			<noscript>
				<div style={{ zIndex: '-999', position: 'relative' }}>
					{rbWidgetLinks?.map(({ link, text }) => (
						<a key={link} href={link}>
							{text}
						</a>
					))}
				</div>
			</noscript>
			<LazyHydrate whenVisible>
				<RBWidget
					locale={locale}
					rbWidgetInfo={{
						links: [],
						isShowTabs: false,
						totalBookieCount: data?.totalBookieCount,
						totalCasinoCount: data?.totalCasinoCount,
						activeTab: showBookie ? 0 : 1,
					}}
					className={className}
					bookies={data?.bookies || []}
					casinos={data?.casinos || []}
					allBookieLink={`${TRIBUNA_COM_URL}${locale}/${BETTING_SPORT_URLS?.[locale]}/${bookieDefRatingUrls?.[locale]}/`}
					allCasinoLink={`${TRIBUNA_COM_URL}${locale}/casino/${casinoDefRatingUrls?.[locale]}/`}
				/>
			</LazyHydrate>
		</>
	);
};

export default RBWidgetHOC;
